import axios from "axios";
import { api } from "@crewos/shared";

export const syncApi = {
  doSync: () => {
    return axios(`/api/sync`, {
      baseURL: process.env.REACT_APP_API_BASE_URL,
      withCredentials: true,
      method: "post",
    })
      .then((result) => result.data)
      .catch((err) => api.handleError(err));
  },
};
